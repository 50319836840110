@import './variable.scss';
@import './extend.scss';
@import './mixin.scss';



body {
  background: #F5F5F5;
  font-family: 'segoeui';
  @include fontSize($font14);
	}
h2{
    line-height:40px;
    font-weight: 600;
    @include fontSize($h2Font);
    @include xxxlgmin {
      @include fontSize(34px);
    }
    margin-bottom: 16px;
}
h3{
  @include fontSize($font24);
  @include xxxlgmin {
    @include fontSize($font26);
  }
  line-height:32px;
  margin-bottom: 16px;
  font-weight: 600;
}
h4{
  @include fontSize($font16);
  margin-bottom: 16px;
}
p{
   @extend %p;
   @include fontSize($font14);
}
a{
   @include font-family(inherit, $hrefLinkColor, 22px, 600, normal); 
   text-decoration: auto;
}
.small-text{
  @include fontSize($font12);
}
hr {
  margin: 1.5rem 0;
  border-color: #E2E2E2;
}
.w-262{
  max-width: 262px
}
.width-430{
  max-width: 430px;
}
.h-70{
  height: 70vh;
}
.mb-40{
  margin-bottom: 40px;
}
.ml-12{
margin-left: 12px;
}
.mb-12{
  margin-bottom: 12px;
  }

input[type=text], 
input[type=password],
input[type=file],
input[type=email],
.form-select, .search-input{
    @extend %input;
    transition: all 0.5s ease;
   }

input[type=text], 
input[type=password],
input[type=file],
input[type=email],
.form-select, .search-input,
textarea{
  &:hover{
    background-color: $colorWhite !important;
  }
  &:focus{
    border-color: $hrefLinkColor !important;
    color: $darkBalck !important;
    box-shadow: unset !important;
  }
}
textarea{
  @extend %textarea;
}

.form-check-label{
  @include font-family(inherit, $textColor, 22px, 400, normal); 
  @include fontSize($font14);
  cursor: pointer;
  word-break: break-all;
  white-space: break-spaces;
}

label{
    @extend %p;
    font-size: 16px;
    font-weight: 600;
}

.form-check-input{
  &:focus{
    box-shadow: unset;
    border-color:rgba(0,0,0,.25);
  }
  &:checked{
    background-color: $primaryColor;
      border-color:  $primaryColor !important;
      +.form-check-label{
        color: $darkBalck;
    }
  } 
  
}

.btn{
  &:active,  &:focus{
    box-shadow: unset !important;
   }
}
  
.btn-primary{
  @extend %btn;
  @extend %btn-primary;
  transition: all 0.5s ease;
  &:hover{
    background-color: $primaryLight;
    border-color: $primaryLight;
  }
  &:active,  &:focus{
    background-color: $primaryDark;
    border-color: $primaryDark;
   }
}
.btn-info{
  @extend %smallBtn;
  @extend %btn-info;
  @include fontSize($font12);
  line-height: 16px;
  width: max-content;
  &:hover, &:active,  &:focus{
  @extend %btn-info;
  @extend %smallBtn;
  
 }
}
.btn-success{
  @extend %smallBtn;
  @extend %btn-success;
  @include fontSize($font12);
  line-height: 16px;
  &:hover, &:active,  &:focus{
  @extend %btn-success;
  
 }
}

.btn-danger{
  @extend %smallBtn;
  @extend %btn-danger;
  @include fontSize($font12);
  line-height: 16px;
  &:hover, &:active,  &:focus{
  @extend %btn-danger;
  
 }
}

.btn-light{
  @extend %btn;
  @extend %btn-light;
  &:hover{
    @extend %btn-light;
    background-color: $baseColor;
   }
   &:active,  &:focus{
    background-color: $baseColor;
    border-color: $darkBalck;
    color: $darkBalck;
   }
}
.dropdown-item.active, .dropdown-item:active {
  background-color: var(--bs-dropdown-link-hover-bg);
}

.custom-search-list {
  // min-height: 100px;
  max-height: 300px;
  overflow-y: scroll;
}
/*=========== Login page Common css ===============*/

.bg-image {
  background-image: url(../../assets/images/img-dyf.jpg);
  @extend %background-image;
  position: relative;
  justify-content: center;
  align-items: center;

  .overlay {
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      width: 100%;
      height: 100%;
  }

  .inner-div {
      z-index: 1;
      width: 508px;
      text-align: center;
  }

  .color-white {
      color: $colorWhite;
  }
}

.right-div {
  h2 {
      color: $primaryColor;
  }

  padding-right: 4.75rem !important;
  padding-left: 4.75rem !important;

}

/*================================================*/
.align-right {
  text-align: right;
}

.eye_icon {
  right: 16px;
  top: 42px;
  cursor: pointer;
}

.invalid-feedback{
  @include font-family(inherit, $dangerColor, 22px, 400, normal); 
  @include fontSize($font14);
  .error-icon{
    margin-right: 4px;
  }
}

.box-shadow{
    box-shadow:0px 0px 5px rgba(0, 0, 0, 0.15);
    border: unset;
    border-radius: 4px;
}
.tootip-content:hover{
  .tooltip-inner{
    background-color: $lightBlack;
    box-shadow: 0px 3px 6px -3px rgba(23, 24, 24, 0.08), 0px 8px 20px -4px rgba(23, 24, 24, 0.12);
    border-radius: 8px;
    padding: 8px;
  }
  .tooltip-arrow{
    &:before{
      border-top-color: $lightBlack !important;
    }
  }
}

.card-body{
  padding: 24px 16px;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background:rgba(0,0,0,0.55);
  z-index: 9999;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   svg {
    position: relative;
    margin-bottom: 16px;
}
.loader-message {
  position: relative;
  color: #fff;
}
}

.m-b-16{ margin-bottom: 16px;}
.pl-8{padding-left: 8px;}
.pl-16{padding-left: 16px;}
.mb-42{
    margin-bottom: 42px;
}

/*********** BS Modal ************/
.modal-md {
  --bs-modal-width: 380px;
}
.fade.modal-backdrop.show {
  --bs-modal-box-shadow: $darkBalck;
  opacity: 0.55;
}
.modal-content {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 26px 80px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: unset;
  .modal-title{
    @include font-family(inherit, $darkBalck, 30px, 600, normal);
    @include fontSize($font20);
    @include xxxlgmin {
      font-size: 24px;
    }
  }
}

/************* pagination *****************/

.pagination-filter {
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;
 .form-select {
    width: auto;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 8px !important;
    min-height: 40px;
    @include fontSize($font12);
    height: 40px !important;
    margin: auto 12px;
}
}

.pagination-container{
  &.pagination-bar{
    justify-content: end;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
  }
  .pagination-item{
    margin: auto 12px !important;
    border: 1px solid #B6B6B6;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    font-size: $font20 !important;
    width: 36px;
    height: 36px !important;
    justify-content: center;
    &:first-child{
      border-radius: 4px 0px 0px 4px !important;
    }
    &:last-child{
      border-radius: 0px 4px 4px 0px !important;
    } 
    .arrow{
      &:before{
        width: 0.5em;
        height: 0.5em;
      }
     
      &.left {
        position: relative;
        right: 1px;
    }
    &.right {
      position: relative;
      left: 1px;
      bottom: 2px;
  }
    }
  }
} 

  /**************** Home Page *********************/
  .card-body{
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .search-input {
        width: 300px;
        padding-right: 36px !important;
    }
    .search-icon{
      position: absolute;
      right: 26px;
      top: 10px;
    }
    .tune {
      align-items: center;
      justify-content: center;
      @extend %border;
      margin: 0;
      width: 48px;
      height: 48px;
  }
  .add-btn{
    min-width: 190px;
  }
  #dropdown-custom-2 {
    padding: 0 12px;
}
  }
  }
  .custom-table{
    thead{
      background: $baseColor;
      tr{
        th{
          padding: 16px 14px;
        &:last-child{
          width: 100px;
        }
        }
      }
    }
    tbody{
      tr{
        td{
          padding: 12px 14px;
          &:last-child{
            width: 100px;
          }
          
          .view-more{
            color:$hrefLinkColor;
            font-weight: 600;
            cursor: pointer;
          }
          .btn-link{
            padding: 0;
            text-decoration: none;
            &button:not(:disabled){
              border: unset;
            }
            &:focus{
              box-shadow: unset;
            }
            &.border-left {
              border-radius: unset;
              margin-left: 18px;
          }
          }
         }
      }
    }
  }

  .multiple-select {
    @include font-family(inherit, $textColor, 16px, 400, normal); 
    @include fontSize($font12);
    background: #FAFBFC;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    font-size: 12px;
    padding: 6px 8px;
    margin-right: 8px;
    .cross-icon {
        padding-left: 4px;
        position: relative;
        bottom: 2px;
    }
  
    &.unset-style{
        border: unset;
        background: unset;
        padding: 6px 0px !important;
        margin: 0;
        @include font-family(inherit, $textColor, 16px, 400, normal); 
        @include fontSize($font14);
    }
  }
    /*================ No Customer, No Category , no Videos =====================*/
    .no-customer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      h3{
        font-size: $font20;
      }
      p{
        color: $textColor;
      }
  }