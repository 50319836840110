$baseColor: #FAFBFC;
$primaryColor: #D92E86;
$primaryLight:#F1489F;
$primaryDark:#C31B72;
$secondaryColor:#929292;
$textColor: #5A5A5A;
$darkBalck: #010101;
$mediumBlack:#929292 ;
$lightBlack:#353535;
$colorWhite: #fff;
$hrefLinkColor: #2C6ECB;
$successColor: #008060;
$dangerColor: #D72C0D;
$borderColor: #DFE1E6;
$statusBg: #F5F5F5;
$colorYellow:#FFC000;
$inputColor:#5A5A5A ;

//FONTS Sizes
$font12: 12px;
$font14: 14px;
$font16: 16px;
$font20: 20px;
$font24: 24px;
$font26: 26px;
$font28: 28px;
$h2Font: 32px;