@mixin font-family($font-name,  $color, $line-height, $font-weight, $letter-spacing) {
    font-family: $font-name;
    color: $color;
    line-height: $line-height;
    font-weight: $font-weight;
    letter-spacing: $letter-spacing;
  }
  
  @mixin fontSize($font-size) { 
    font-size: $font-size;
    @media only screen and (min-width: 1899.98px) {
      font-size: $font-size+(2);
    }
  }
  
  @mixin xxxlgmin {
    @media only screen and (min-width: 1899.98px) {
      @content;
    }
  }
  @mixin xxlmax {
    @media only screen and (max-width: 1440.98px) {
      @content;
    }
  }

  @mixin xxxlmin {
    @media only screen and (min-width: 1499.98px) {
      @content;
    }
  }
  @mixin x {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }
  @mixin xl {
    @media only screen and (min-width: 1200.98px) {
      @content;
    }
  }
  
  @mixin xlmax {
    @media only screen and (max-width: 1279.99px) {
      @content;
    }
  }
  @mixin xxxl {
    @media only screen and (max-width: 1799.98px) {
      @content;
    }
  }
  @mixin xxl {
    @media only screen and (max-width: 1399.98px) {
      @content;
    }
  }
  @mixin xxlmin {
    @media only screen and (min-width: 1400px) {
      @content;
    }
  }

/*tablets,desktops, grater than 992px*/
@mixin mdUp{
  @media only screen and (min-width:992.98px) {
    @content;
  }
}
/* Medium devices (tablets, less than 992px) */
@mixin md{
  @media only screen and (max-width:991.98px) {
    @content;
  }
}
/*tablets,desktops, grater than 768px*/
@mixin smUp {
  @media only screen and (min-width: 768px) {
    @content;
  }
}
/* Small devices (landscape phones, less than 768px)*/
 @mixin sm {
  @media only screen and (max-width: 767.98px) {
    @content;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@mixin xs {
  @media only screen and (max-width: 576.98px) {
    @content;
  }
}

/* Extra small devices (portrait phones, less than 413px) */
@mixin xxs {
  @media only screen and (max-width: 414.98px) {
    @content;
  }
}


@mixin xs {
  @media only screen and (min-width: 320.98px) {
    @content;
  }
}