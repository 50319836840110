@import '../../assets/scss/variable.scss';
@import '../../assets/scss/extend.scss';
@import '../../assets/scss/mixin.scss';
#header-wrapper {
    padding-left: 0;
    transition: all 0.5s ease;
    position: relative;
    
  }
  .toggled{
    #navbar-wrapper {
      position: absolute;
      margin-right: -250px;
      #sidebar-wrapper {
        width: 260px;
      }
  }  
}+ #content-wrapper {
    padding-left: 92px;
  }
  #sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    overflow-x: hidden;
    background:$darkBalck;
    transition: all 0.5s ease;
 
    .sidebar-brand {
      position: absolute;
      top: 0;
      width: 260px;
      text-align: center;
      padding: 16px 0;
      border-bottom: 1px solid rgba(254, 254, 254, 0.2);
      .brands{
        img{
          display: none;
        }
        .large-logo{
          display: inline-block;
        }

        .large-logo + .small-brand{
          display: none;
        }
        .small-brand {
         .small-logo{
          display: block;
          margin: auto;
         }
          width: 60px;
          text-align: center;
          height: 46.28px;
          display: flex;
       }
      }
      
      h2 {
        margin: 0;
        font-weight: 600;
        font-size: 24px;
        color: #fff;
      }
    }
    
  .sidebar-nav {
    position: absolute;
    top: 75px;
    width: 260px;
    margin: 24px 0 0;
    padding: 0;
    list-style: none;
    li {
      line-height: 42px;
      margin-bottom: 16px;
      a {
        display: block;
        text-decoration: none;
        @include font-family(inherit, $colorWhite, 22px, 600, normal);
         padding: 13px 22px;
         
      &.active{
          text-decoration: none;
          color: #fff;
          background: $primaryColor;
      }
      &:hover{
          text-decoration: none;
          color: #fff;
          background: $lightBlack;
      }
      }
     
    }
  }
  }
 
  #navbar-wrapper {
      width: 100%;
      position: absolute;
      z-index: 2;
  }
  .dropdown-custom {
    text-decoration: none;
    padding: 0;
    &:after{
      display: none !important;
    }
}
  #navbar-wrapper{ 
    .navbar {
      border-width: 0 0 0 0;
      background-color: #fff;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
      font-size: 24px;
      margin-bottom: 0;
      border-radius: 0;
      .navbar-brand {
        color: $textColor;
        cursor: pointer;
        position: relative;
       
        img{
          background: $darkBalck;
          padding: 8px 8px 8px 5px;
          border-radius: 0px 4px 4px 0px;
        }
        &:hover {
          color: $textColor;
          background: $baseColor;
        }
      }
    .navbar-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 32px 0 0;
      .nav-left { 
        display: flex;
        align-items: center;
        position: relative;
        bottom: 2px;
      }
      .right-content {
        justify-content: center;
        align-items: center;
        .avatar {
          img {
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
        }
        }
       .data {
          margin-left: 10px;
          text-align: left;
          p{
            margin: 0;
          }
          .name{
            font-weight: 600;
            color: $darkBalck;
          }
          .designation{
            @include fontSize($font12);
          }
      }
      
    }
   }
   .back-link{
    @include fontSize($font14);
    @include font-family(inherit, $darkBalck, 20px, 400, normal);
   }
  }
}
   #content-wrapper {
    width: 100%;
    position: absolute;
    padding: 28px 32px;
    top: 100px;
    padding-left: 292px;
    transition: all 0.5s ease;
  }
  
  
  @include mdUp {
    #header-wrapper {
      padding-left: 260px;
      &.toggled {
        padding-left: 60px;
        #sidebar-wrapper {
          width: 60px;
        }
         #navbar-wrapper{
      position: relative;
      margin-right: 60px;
    }+ #content-wrapper {
      position: relative;
      margin-right: 60px;
    }
      }
       #sidebar-wrapper {
        width: 260px;
      }
    }
  .toggled + #content-wrapper {
        padding-left: 92px;
    }
   #navbar-wrapper {
      position: relative;
    }
   #header-wrapper.toggled {
      padding-left: 60px;
    }
  #content-wrapper {
      position: relative;
      top: 0;
    }
  
    
  }
  
  @include md {
    #header-wrapper {
      padding-left: 250px;
    }
  
    #sidebar-wrapper {
      width: 250px;
    }

   #navbar-wrapper {
      position: relative;
    }
  
    #header-wrapper.toggled {
      padding-left: 60px;
    }
  
    #content-wrapper {
      position: relative;
      top: 0;
      padding-left: 292px;
    }
  
    .toggled{
      #sidebar-wrapper {
        width: 60px;
      }
      #navbar-wrapper,
     #content-wrapper {
      position: relative;
      margin-right: 250px;
    }
    }
    .toggled + #content-wrapper {
      padding-left: 92px;
  }
  }
  #dropdown-custom-1{
    padding: 6px;
    text-decoration: none;
    +.dropdown-menu.show {
    right: 0px !important;
    top: 6px;
    .dropdown-item{
      img{
        padding-right: 8px;
      }
      
    }
    }
    &:hover, &:focus{
    background: $baseColor;
    border-radius: 8px;
    padding: 6px;
    }
  }
  .dropdown-menu.show {
     min-width: 130px;
    display: block;
    box-shadow: 0px 3px 6px -3px rgba(23, 24, 24, 0.08), 0px 8px 20px -4px rgba(23, 24, 24, 0.12);
    border-radius: 8px;
    border: unset;
    .dropdown-item{
      padding: 12px 16px;
      img{
        background: unset !important;
      }
    }
}
  