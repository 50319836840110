@import './variable.scss';
%background-image{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
%input{
    background-color: $baseColor;
    border: 1px solid $borderColor !important;
    border-radius: 10px !important;
    padding: 13px 16px !important;
    height: 48px !important;
    color: $inputColor !important;
}
%textarea{
    background-color: $baseColor !important;
    border: 1px solid $borderColor !important;
    border-radius: 10px !important;
    padding: 13px 16px !important;
}
%p{
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: $lightBlack;
}
%btn{
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    height: 48px;
    border-radius: 10px;
    box-shadow: unset;
}

%smallBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px 7px;
    height: 24px;
    border-radius: 5px;
    box-shadow: unset;
    color: $colorWhite;
}

%btn-primary{
    background: $primaryColor;
    border-color: $primaryColor;
    
}

%btn-info{
    background: $hrefLinkColor;
    border-color: $hrefLinkColor;
    padding: 4px 8px;
    height: 24px;
    border-radius: 5px;
}

%btn-success{
    background: $successColor;
    border-color: $successColor;
}

%btn-danger{
    background: $dangerColor;
    border-color: $dangerColor;
}
%btn-light{
    background: $colorWhite;
    border-color: $secondaryColor;
    color: $textColor;
   }

%border{
    border: 1px solid $secondaryColor;
    border-radius: 10px;
}
%status{
    background: $statusBg;
    border-radius: 5px;
    width: auto;
    padding: 4px 8px;
}